import {
  CartProduct,
  ConfigLoader,
  Country,
  Fabric,
  OrderInformation,
  Product,
  ProductGroup,
  ProductOption,
  ProjectLogo,
  SizingObject,
  Supplier,
} from "./types";

export const TAX_RATE = 0.21;
const verbose = false;

const uuidPattern =
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

export const getMininumOrderQuantity = (settings: any) => {
  if (!settings || !settings.length) return 0;
  return settings[0].data?.minimum_quantity || 0;
};
export const getShippingCosts = (settings: any) => {
  if (!settings || !settings.length) return 0;
  return settings[0].data?.shipping_cost || 0;
};

// get price for one product including all options and fabrics
export const getCartItemPrice = (
  cartItem: CartProduct,
  fabricSupplier: Supplier | null,
): number => {
  const prices = [];
  if (!cartItem || !cartItem.product) {
    return 0;
  }
  // Product price
  if (fabricSupplier) {
    const productFabricPrice = getProductFabricPrice(
      cartItem.product,
      fabricSupplier,
    );
    if (typeof productFabricPrice === "number") {
      prices.push(productFabricPrice);
    } else {
      prices.push(cartItem.product?.data?.price || 0);
    }
  } else {
    prices.push(cartItem.product?.data?.price || 0);
  }
  // Fabric prices
  prices.push(cartItem.fabric?.main?.data?.price || 0);
  prices.push(cartItem.fabric?.contrast?.data?.price || 0);
  // Product Features prices with fabric supplier
  if (fabricSupplier) {
    const productOptionsPrices = cartItem.productOptions?.map(
      (option: ProductOption) => {
        const productFeatureFabricPrice = getProductFeatureFabricPrice(
          option,
          fabricSupplier,
        );
        if (typeof productFeatureFabricPrice === "number") {
          return productFeatureFabricPrice;
        }
        return option?.data?.price || 0;
      },
    );
    prices.push(productOptionsPrices);
    // Product Features prices without fabric supplier
  } else {
    const productOptionsPrices = cartItem.productOptions?.map(
      (option: ProductOption) => {
        return option?.data?.price || 0;
      },
    );
    prices.push(productOptionsPrices);
  }
  //
  const totalPrice = prices.flat().reduce((acc, curr) => {
    if (typeof curr === "number" && typeof acc === "number") {
      return acc + curr;
    }
    return acc;
  }, 0);

  if (verbose) {
    console.log(
      "Unit Price",
      prices.flat(),
      "total price is",
      totalPrice,
      "fabricSupplier is ",
      fabricSupplier?.id,
    );
  }
  return totalPrice || 0;
};

// get total amount of items for all gender options and sizes for one product
export const getSizesAmount = (cartItem: CartProduct) => {
  let total = 0;
  cartItem.amount?.forEach((genderOption: SizingObject) => {
    genderOption.items?.forEach((size) => {
      total += size.amount || 0;
    });
  });
  return total;
};

// get price for total of all sizes for one product
export const getCartItemSizesPrice = (
  cartItem: CartProduct,
  fabricSupplier: Supplier | null,
) => {
  const sizesAmount = getSizesAmount(cartItem);
  const price = getCartItemPrice(cartItem, fabricSupplier);
  return price * sizesAmount;
};

// get price for total cart items exl taxes and shipping
export const getCartPrice = (products: any, fabricSupplier: Supplier | null) =>
  products?.reduce(
    (acc: any, cartItem: any) =>
      acc + getCartItemSizesPrice(cartItem, fabricSupplier),
    0,
  ) || 0;

// calculate taxes for cart price ( default 21% )
export const getCartTaxes = (cartPrice: any): number => cartPrice * TAX_RATE;

// get cart total price including taxes and shipping , apply discount if present
export const getCartTotal = (
  products: any,
  settings: any,
  discount = 0,
  fabricSupplier: Supplier | null,
): number => {
  const cartPrice = getCartPrice(products, fabricSupplier);
  const total =
    cartPrice + getCartTaxes(cartPrice) + getShippingCosts(settings);
  const discountPrice = getPriceDiscount(total, discount);
  return total - discountPrice;
};

// get discount amount of a price
export const getPriceDiscount = (price: number, discount = 0): number => {
  if (!price) {
    return 0;
  }
  return price * (discount / 100);
};

export const totalCartProductAmount = (cartItem: CartProduct) => {
  if (!cartItem) return 0;
  return getSizesAmount(cartItem);
};

const getPriorityForProductOption = (
  productOption: ProductOption,
  product: Product,
): number => {
  return (
    product.data.product_options.find(
      (option: any) => option.product_option.id === productOption.id,
    )?.priority || Infinity
  );
};

export const sortProductOptionsByPriority = (product: Product) => {
  return (a: ProductOption, b: ProductOption) => {
    const aPriority = getPriorityForProductOption(a, product);
    const bPriority = getPriorityForProductOption(b, product);
    return aPriority - bPriority;
  };
};

export const updateProductOption = (
  productOption: ProductOption,
  productOptions: ProductOption[],
): ProductOption[] => {
  return productOptions.map((curr) => {
    if (curr.id === productOption.id) {
      return productOption;
    }
    return curr;
  });
};

export const isProductOptionWithLogo = (productOption: ProductOption) =>
  productOption.data.meshes.some((mesh) => mesh.variant === "logo_mesh");

// Get ProductOption variant price
// Returns false no variant price is available
// Returns null if no variant price is found
// Current implementation only takes ProductLogo variant prices into account
export const calcProductOptionVariantPrice = (
  productOption: ProductOption,
  projectLogos: ProjectLogo[],
  fabricSupplier: Supplier | null,
) => {
  if (!productOption) {
    return false;
  }
  if (fabricSupplier && productOption.data.prices) {
    const supplierPrice = productOption.data.prices.find(
      (curr) =>
        curr.supplier.id === fabricSupplier.id &&
        typeof curr.supplier_price === "number",
    );
    if (supplierPrice) {
      return supplierPrice.supplier_price;
    }
  }
  if (!Array.isArray(productOption.data.variants)) {
    return false;
  }
  if (productOption.data.logo_option) {
    const projectLogo = projectLogos.find(
      (curr) => curr.logoName === productOption.data.logo_option,
    );
    if (projectLogo && projectLogo.logoOption) {
      const variantName = projectLogo.logoOption.toLowerCase();
      const variant = productOption.data.variants.find(
        (curr) => curr.variant_name.toLowerCase() === variantName,
      );
      if (variant?.variant_price) {
        return variant.variant_price;
      }
    }
    return 0;
  }
  return false;
};

// convert saved product config from database to cart item
export const convertLoadedProductToCartItem = (
  productConfig: ConfigLoader,
  products: Product[],
  productGroups: ProductGroup[],
  productOptions: ProductOption[],
  fabrics: Fabric[],
): CartProduct | null => {
  const product = products?.find(
    (product: Product) => product.id === productConfig.product,
  );
  const productGroup = productGroups?.find(
    (productGroup: ProductGroup) =>
      productGroup.id === productConfig.productGroup,
  );
  if (!product || !productGroup) {
    return null;
  }
  const selectedProductOptions = [] as ProductOption[];
  productConfig.productOptions?.forEach((productOption) => {
    const option = productOptions?.find(
      (option: ProductOption) => option.id === productOption.id,
    );
    if (option) {
      selectedProductOptions.push({
        ...option,
        data: {
          ...option.data,
          color_option: productOption.colorOption || option.data.color_option,
        },
      });
    }
  });

  return {
    id: productConfig.id,
    product,
    productGroup,
    fabric: {
      main: fabrics?.find(
        (fabric: Fabric) => fabric.id === productConfig.mainFabric,
      ),
      contrast: fabrics?.find(
        (fabric: Fabric) => fabric.id === productConfig.contrastFabric,
      ),
    },
    productOptions: selectedProductOptions,
    projectLogos: productConfig.projectLogos,
    productModelPicture: productConfig.productModelPicture,
    amount: product?.data?.body,
  };
};

// map user and customer data to order information
export const getDefaultOrderInformation = (
  customer: any,
  user: any,
  orderOptions: any,
) => {
  return {
    companyName: customer?.name || "",
    vat: customer?.vat || "",
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    phone: user?.phone_number || "",
    billingAddress: {
      street: orderOptions?.invoiceAdress?.street || "",
      street2: orderOptions?.invoiceAdress?.street2 || "",
      zipCode: orderOptions?.invoiceAdress?.zipCode || "",
      city: orderOptions?.invoiceAdress?.city || "",
    },
  } as OrderInformation;
};

const convertCartItemsToProductConfig = (property: any, discount = 0) => {
  if (!property) return null;
  const priceDiscount = getPriceDiscount(property.data?.price, discount);
  return {
    id: property.id,
    data: {
      type: property.data?.type,
      name: property.data?.name,
      sku: property.data?.sku,
      color: property.data?.web_format || property.data?.color_option,
      price: property.data?.price - priceDiscount,
      rrp: property.data?.price,
    },
  };
};

// map cart items to order object for API, remove unused properties
export const mapCartToOrder = (
  cartItems: CartProduct[],
  fabricSupplier: Supplier | null,
  projectId?: string,
  orderInformation?: OrderInformation,
  userId?: string,
  discount?: number,
  settings?: any,
) => {
  return {
    project: projectId,
    userId: userId,
    cart: cartItems.map((cartItem: CartProduct) => {
      return {
        id: cartItem.id,
        productModelPicture: cartItem.productModelPicture,
        productGroup: convertCartItemsToProductConfig(
          cartItem.productGroup,
          discount,
        ),
        product: convertCartItemsToProductConfig(cartItem.product, discount),
        mainFabric: convertCartItemsToProductConfig(
          cartItem.fabric?.main,
          discount,
        ),
        contrastFabric: convertCartItemsToProductConfig(
          cartItem.fabric?.contrast,
          discount,
        ),
        sizing: cartItem.amount,
        productOptions: cartItem.productOptions?.map(
          (option: ProductOption) => {
            const productConfig = convertCartItemsToProductConfig(option, discount);
            if (productConfig) {
              if (!option.data.fabrics && productConfig.data.color) {
                delete productConfig.data.color;
              }
              if (option.data.fabrics && typeof productConfig.data.color === 'undefined') {
                 productConfig.data.color = 'main';
              }
            }
            return productConfig;
          },
        ),
        projectLogos: cartItem.projectLogos,
      };
    }),
    totalPrice: getCartTotal(
      cartItems,
      settings,
      discount,
      fabricSupplier,
    ).toFixed(2),
    totalRRP: getCartTotal(cartItems, settings, 0, fabricSupplier).toFixed(2),
    distributor: orderInformation?.distributor?.id,
    orderInformation: { ...orderInformation },
  };
};

// get the translated property from prismic data
// example : getPrismicTranslation('fr', 'name', data) => data.name_fr
export const getPrismicTranslation = (
  locale: string,
  property: string,
  data: any,
) => {
  if (!data) return "";
  const translatedProperty = `${property}_${locale}`;
  return data[translatedProperty] || data[property];
};

// Get product feature prices based on fabric supplier. Returns false if no fabricSupplier is provided or no fabricSupplier price is found.
export function getProductFeatureFabricPrice(
  productOption: ProductOption,
  fabricSupplier: Supplier | null,
): number | boolean {
  if (
    productOption &&
    fabricSupplier &&
    productOption.data.prices &&
    productOption.data.prices.find(
      (curr) =>
        curr.supplier.id === fabricSupplier.id &&
        typeof curr.supplier_price === "number",
    )
  ) {
    const price = productOption.data.prices.find(
      (curr) => curr.supplier.id === fabricSupplier.id,
    );
    if (price) {
      return price.supplier_price;
    }
  }
  return false;
}

// Get Product price based on fabric supplier. Returns false if no fabricSupplier is provided or no fabricSupplier price is found.
export function getProductFabricPrice(
  product: Product,
  fabricSupplier: Supplier | null,
): number | boolean {
  if (
    product &&
    fabricSupplier &&
    product.data.prices &&
    product.data.prices.find(
      (curr) =>
        curr.supplier.id === fabricSupplier.id &&
        typeof curr.supplier_price === "number",
    )
  ) {
    const price = product.data.prices.find(
      (curr) => curr.supplier.id === fabricSupplier.id,
    );
    if (price) {
      return price.supplier_price;
    }
  }
  return false;
}

// Define a mapping from country codes to language names
export const languageMapping: Record<Country, string> = {
  en: "English",
  nl: "Nederlands",
  de: "Deutsch",
  fr: "Français",
};

export function isValidProjectIdFormat(projectId: string | undefined): boolean {
  if (projectId && uuidPattern.test(projectId)) {
    return true;
  }
  return false;
}
