import { useContext, FC, useState, useEffect, useRef } from "react";
import { Color3 } from "@babylonjs/core/Maths/math";
import { SceneContext } from "react-babylonjs";
import { Product, ProductOption, ProjectLogo } from "../../types";
import {
  getAllProductOptionMeshesWithFabric,
  getNodesByName,
  getProductOptionLogoMeshes,
  setMeshLogoTexureUrl,
} from "./utils";
import {
  AbstractMesh,
  Camera,
  Node,
  PBRMaterial,
  Texture,
} from "@babylonjs/core";
import { defaultCameraName } from "./SceneEnvironment";
import { isProductOptionWithLogo } from "../../utils";

export type ProductModelTagsType = {
  product: Product | undefined;
  productOptions: ProductOption[] | null;
  productModelIsReady: boolean;
  projectLogos: Array<ProjectLogo>;
  color?: Color3;
  showHighlight: boolean;
  // Showing and hidding meshes should be handeled by the parent component
  onEnableMeshes: (meshes: Node[], value: boolean, force?: boolean) => void;
  selectedProductOptions: Array<ProductOption> | undefined;
};

const renderMeshOverlay = false; // the mesh overlay will always show on the prodcut mesh in the shopping cart. disable for now.
const showDefaultLogoTexture = false;
const ProductModelLogos: FC<ProductModelTagsType> = (props) => {
  const {
    product,
    productOptions,
    productModelIsReady,
    projectLogos,
    showHighlight,
    onEnableMeshes,
    selectedProductOptions,
  } = props;

  const [meshes, setMeshes] = useState<Array<Node>>([]);
  const [logoTextureUrls, setLogoTextureUrls] = useState<Map<string, string>>();
  const [defaultCamera, setDefaultCamera] = useState<Camera | null>(); // only show highlight layer on this camera
  const highlightLayerRef = useRef<any>(null);
  const ctx = useContext(SceneContext);

  useEffect(() => {
    if (ctx.scene?.isReady) {
      setDefaultCamera(ctx.scene.getCameraById(defaultCameraName));
    }
  }, [ctx]);

  useEffect(() => {
    setMeshes([]);
  }, [productModelIsReady]);

  // Find logo meshes in the Product mesh model
  useEffect(() => {
    if (
      !productModelIsReady ||
      !product ||
      product.data.meshes.length === 0 ||
      !ctx.scene
    ) {
      return;
    }

    const productMeshNames = product.data.meshes
      .filter((curr) => curr.default_fabric === "logo_mesh")
      .map((curr) => curr.mesh);

    // Get "productOption.meshes" logo meshes
    const productOptionMeshNames = getAllProductOptionMeshesWithFabric(
      productOptions || [],
      "logo_mesh",
    );

    // Get "Product.meshes" logo meshes
    const meshes = getNodesByName(ctx.scene, [
      ...productMeshNames,
      ...productOptionMeshNames,
    ]);

    // Get product logo mesh texture info
    meshes.forEach((mesh) => {
      if (mesh instanceof AbstractMesh) {
        const material = mesh.material as PBRMaterial;
        if (material && material.albedoTexture) {
          const albedoTexture = material.albedoTexture as Texture;
          // Keep the original texture size to calculate the new image UV scale later.
          if (!mesh.metadata) {
            mesh.metadata = {};
          }
          if (!mesh.metadata.originalTextureName) {
            const { width, height } = albedoTexture.getSize();
            mesh.metadata.originalTextureWidth = width;
            mesh.metadata.originalTextureHeight = height;
            mesh.metadata.originalTextureName = albedoTexture.name;
            mesh.metadata.originalTextureUrl = albedoTexture.url;
            mesh.metadata.fabricVariant = "logo_mesh";
          }
        }
      }

      // onEnableMeshes([mesh], false);
    });
    //
    setMeshes(meshes);
  }, [product, productOptions, productModelIsReady, ctx.scene, onEnableMeshes]);

  // Toggle outline effect mesh
  useEffect(() => {
    if (!highlightLayerRef.current) {
      return;
    }

    const color = props.color || new Color3(0.1686, 0.2863, 0.6275);
    if (ctx.scene?.getNodeByName(defaultCameraName)) {
      highlightLayerRef.current.setca =
        ctx.scene?.getNodeByName(defaultCameraName);
    }

    meshes.forEach((mesh) => {
      // Highlight Layer
      if (showHighlight) {
        highlightLayerRef.current.addMesh(mesh, color);
        // Show logo mesh
        onEnableMeshes([mesh], true);
      } else {
        highlightLayerRef.current.removeMesh(mesh);
      }
      // Mesh overlay
      if (renderMeshOverlay && mesh instanceof AbstractMesh) {
        mesh.renderOverlay = showHighlight;
        mesh.overlayColor = color;
      }
    });
  }, [meshes, showHighlight, props.color, ctx.scene, onEnableMeshes]);

  // Possible "states":
  //   - No highlight and no product logo url: hide logo meshes
  //   - No highlight and product logo url: show logo meshes with product logo url
  //   - Highlight and no product logo url: show logo meshes with default logo url
  //   - Highlight and product logo url: show logo meshes with product logo url
  useEffect(() => {
    if (logoTextureUrls) {
      // Show uploaded logo
      meshes.forEach((mesh) => {
        if (!ctx.scene) {
          return;
        }
        if (mesh instanceof AbstractMesh) {
          const texture_url = logoTextureUrls.get(mesh.name || "default");
          // Show logo mesh
          if (texture_url) {
            setMeshLogoTexureUrl(mesh, texture_url, ctx.scene);
          } else {
            const originalTextureUrl = mesh.metadata.originalTextureUrl;
            // Use alpha 0 to hide the original texture on the mesh model
            setMeshLogoTexureUrl(mesh, originalTextureUrl, ctx.scene, showDefaultLogoTexture ? 1 : 0);
          }
          if (showHighlight) {
            onEnableMeshes([mesh], true, true);
          }
        }
      });
    } else if (!showHighlight) {
      onEnableMeshes(meshes, false);
    } else if (showHighlight && !logoTextureUrls) {
      // Show uploaded logo
      meshes.forEach((mesh) => {
        if (!ctx.scene) {
          return;
        }

        if (mesh instanceof AbstractMesh) {
          if (!mesh.metadata) {
            onEnableMeshes([mesh], true, true);
            return;
          }
          // Show logo mesh
          const originalTextureUrl = mesh.metadata.originalTextureUrl;
          setMeshLogoTexureUrl(mesh, originalTextureUrl, ctx.scene, showDefaultLogoTexture ? 1 : 0);
          onEnableMeshes([mesh], true, true);
        }
      });
    }
  }, [meshes, ctx.scene, showHighlight, onEnableMeshes, logoTextureUrls]);

  // Get mesh name and logo name for all selected ProductOptions (as Map<meshName, logoName>)
  useEffect(() => {
    if (
      !selectedProductOptions ||
      selectedProductOptions.length === 0 ||
      projectLogos.length === 0
    ) {
      setLogoTextureUrls(undefined);
      return;
    }
    const logoUrls = new Map(
      projectLogos.map((curr) => [curr.logoName, curr.texture_url]),
    );
    setLogoTextureUrls(
      selectedProductOptions
        .filter(isProductOptionWithLogo)
        .reduce((acc, curr) => {
          const logoUrl = logoUrls.get(curr.data.logo_option);
          if (!logoUrl) {
            return acc;
          }
          getProductOptionLogoMeshes(curr).forEach((meshName) =>
            acc.set(meshName, logoUrl),
          );
          return acc;
        }, new Map<string, string>())
        // Add default texture url
        .set("default", projectLogos[0].texture_url),
    );
  }, [selectedProductOptions, projectLogos]);

  useEffect(() => {}, [ctx.scene]);

  if (defaultCamera && defaultCamera?.name) {
    return (
      <highlightLayer
        name="highlightLayer0"
        ref={highlightLayerRef}
        options={{ camera: defaultCamera }}
      />
    );
  }

  return null;
};

export { ProductModelLogos };
