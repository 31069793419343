import React, { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  selectFabricSupplier,
  selectProjectLogoPlacable,
  selectProjectLogos,
} from "../../../store/reducers/configReducer";
import { ProjectLogoItem } from "./LogoItem";
import { ConfiguratorSelector } from "../ConfiguratorSelector";
import { productLogoPanelOpen } from "../../../store/reducers/uiReducer";
import { logoNames } from "./config";
import { useProductConfiguratorContext } from "../ProductConfiguratorProvider";
import ProductFeature from "../FeatureSelector/ProductFeature";
import { isProductOptionWithLogo } from "../../../utils";
import { ProductOption } from "../../../types";
import Title from "../../ui/Title";

const ProjectLogos: React.FC = () => {
  const dispatch = useAppDispatch();

  const projectLogos = useAppSelector(selectProjectLogos);
  const projectLogoPlacable = useAppSelector(selectProjectLogoPlacable);
  const fabricSupplier = useAppSelector(selectFabricSupplier);

  const {
    onOptionChange,
    addOrRemoveOption,
    productSpecOptions,
    selectedProductOptions,
  } = useProductConfiguratorContext();

  const projectLogoNames =
    projectLogos.length > 0
      ? projectLogos
          .map((curr) => `project_${curr.logoName}`)
          .sort()
          .join(", ")
      : undefined;

  const filteredProductSpecOptions = useMemo(() => {
    return productSpecOptions.filter((item) => isProductOptionWithLogo(item));
  }, [productSpecOptions]);

  return (
    <ConfiguratorSelector
      tabKey={"projectLogos"}
      hasOptions={projectLogoPlacable}
      selected={projectLogoNames}
      title="config_option_title_5"
      onClick={(isOpen) => dispatch(productLogoPanelOpen(isOpen))}
    >
      <>
        {logoNames.map((logoName) => (
          <ProjectLogoItem
            key={logoName}
            logoName={logoName}
            data={projectLogos.find((item) => item.logoName === logoName)}
          />
        ))}

        <Title secondary size={3} label="config_option_title_4" />
        <div className="flex flex-col flex-wrap items-center justify-center gap-1">
          {filteredProductSpecOptions.map((option: ProductOption) => (
            <ProductFeature
              key={option.id}
              defaultOption={selectedProductOptions}
              selectOption={addOrRemoveOption}
              onColorChange={onOptionChange}
              option={option}
              selectedProductOptions={selectedProductOptions}
              fabricSupplier={fabricSupplier}
            />
          ))}
        </div>
      </>
    </ConfiguratorSelector>
  );
};

export { ProjectLogos };
