import { useMemo } from "react";
import { selectFabricSupplier } from "../../../store/reducers/configReducer";
import { ProductOption } from "../../../types";
import { useAppSelector } from "../../../hooks";
import ProductFeature from "./ProductFeature";
import { getPrismicTranslation, isProductOptionWithLogo } from "../../../utils";
import { selectLanguage } from "../../../store/reducers/uiReducer";
import { useProductConfiguratorContext } from "../ProductConfiguratorProvider";
import { ConfiguratorSelector } from "../ConfiguratorSelector";

export const FeatureSelector = () => {
  const {
    onOptionChange,
    addOrRemoveOption,
    productSpecOptions,
    selectedProductOptions,
  } = useProductConfiguratorContext();

  const language = useAppSelector(selectLanguage);
  const fabricSupplier = useAppSelector(selectFabricSupplier);

  const filteredProductSpecOptions = useMemo(
    () => productSpecOptions.filter((item) => !isProductOptionWithLogo(item)),
    [productSpecOptions],
  );

  return (
    <ConfiguratorSelector
      tabKey={"options"}
      hasOptions={filteredProductSpecOptions?.length > 0}
      selected={selectedProductOptions
        ?.map((option) => getPrismicTranslation(language, "name", option?.data))
        .join(", ")}
      title="config_option_title_4"
    >
      <div className="flex flex-col flex-wrap items-center justify-center gap-1">
        {filteredProductSpecOptions.map((option: ProductOption) => (
          <ProductFeature
            key={option.id}
            defaultOption={selectedProductOptions}
            selectOption={addOrRemoveOption}
            onColorChange={onOptionChange}
            option={option}
            selectedProductOptions={selectedProductOptions}
            fabricSupplier={fabricSupplier}
          />
        ))}
      </div>
    </ConfiguratorSelector>
  );
};
