import {
  FC,
  useEffect,
  useState,
  createContext,
  useContext,
  useMemo,
} from "react";
import {
  selectConfigId,
  selectConfigLoader,
  selectProduct,
  selectProductOptions,
  setSelectedProductOptions,
  setProductInitDone,
} from "../../store/reducers/configReducer";
import { ConfigLoader, Product, ProductOption } from "../../types";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useGetDocumentByTypeQuery } from "../../api/prismic";
import { sortProductOptionsByPriority, updateProductOption } from "../../utils";
import { handleConflictOptions, handleOptionsOnRemove } from "./utils";

const mergeConfigLoaderProductOption = (
  configLoader: ConfigLoader | undefined,
  productOption: ProductOption,
): ProductOption => {
  if (configLoader && configLoader.productOptions) {
    const configLoaderProductOption = configLoader.productOptions.find(
      (curr) => curr.id === productOption.id,
    );
    if (configLoaderProductOption) {
      return {
        ...productOption,
        data: {
          ...productOption.data,
          color_option:
            configLoaderProductOption.colorOption ||
            productOption.data.color_option,
          logo_option:
            configLoaderProductOption.logoOption ||
            productOption.data.logo_option,
        },
      } as ProductOption;
    }
  }
  return productOption;
};

interface ProductConfiguratorProviderProps {
  product: Product | undefined;
  productOptions: ProductOption[];
  productSpecOptions: ProductOption[];
  selectedProductOptions: ProductOption[] | undefined;
  onOptionChange: (option: ProductOption) => void;
  addOrRemoveOption: (option: ProductOption) => void;
}

// Create the context
const ProductConfiguratorContext = createContext<
  ProductConfiguratorProviderProps | undefined
>(undefined);

export const ProductConfiguratorProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const dispatch = useAppDispatch();
  const configId = useAppSelector((state) => selectConfigId(state));
  const configLoader = useAppSelector((state) => selectConfigLoader(state));
  const product = useAppSelector<Product | undefined>((state) =>
    selectProduct(state),
  );
  const selectedProductOptions = useAppSelector<ProductOption[] | undefined>(
    (state) => selectProductOptions(state),
  );

  // Should be true after all related data is loaded and used once
  const [initialProductInitDone, setInitialProductInitDone] = useState(false);

  const { data: productOptions, isSuccess } = useGetDocumentByTypeQuery({
    type: "product_option",
    lang: "en-us",
  });

  const productSpecOptions = useMemo(() => {
    if (!product || !productOptions) return [];

    return (
      productOptions
        .filter((productOption: ProductOption) =>
          product.data.product_options.some(
            (mainFabric: any) =>
              mainFabric.product_option.id === productOption.id,
          ),
        )
        .sort(sortProductOptionsByPriority(product))
        // Add configLoader product options like selected fabric color and logo to ProductOption
        .map((productOption: ProductOption) =>
          mergeConfigLoaderProductOption(configLoader, productOption),
        )
    );
  }, [product, productOptions, configLoader]);

  // Reset when switching products
  useEffect(() => {
    if (product) {
      setInitialProductInitDone(false);
    }
  }, [product]);

  useEffect(() => {
    if (initialProductInitDone || !productOptions?.length) {
      return;
    }

    if (!selectedProductOptions?.length && productSpecOptions?.length) {
      setInitialProductInitDone(true);
      dispatch(setProductInitDone());
      if (configId && configLoader) {
        const selectedOptions = productOptions.filter(
          (option: ProductOption) =>
            configLoader.productOptions?.some(
              (productConfigOption) => productConfigOption.id === option.id,
            ),
        );
        dispatch(
          setSelectedProductOptions(
            selectedOptions.map((option: ProductOption) => {
              return mergeConfigLoaderProductOption(configLoader, option);
            }),
          ),
        );
      } else {
        dispatch(
          setSelectedProductOptions(
            productSpecOptions
              .filter((option: ProductOption) => option.data.default)
              .map((option: ProductOption) => {
                const data = {...option.data};
                // Only set "color_option" if fabrics is true
                if (option.data.fabrics) {
                  data.color_option = option.data.color_option || "main";
                }
                return {
                  ...option,
                  data,
                };
              }),
          ),
        );
      }
    }
  }, [
    isSuccess,
    productOptions,
    product,
    configLoader,
    configId,
    dispatch,
    selectedProductOptions,
    initialProductInitDone,
    productSpecOptions,
  ]);

  const addOrRemoveOption = (option: ProductOption) => {
    if (!selectedProductOptions) return;
    const isSelected = selectedProductOptions.some(
      (item) => item?.id === option?.id,
    );
    if (isSelected) {
      dispatch(
        setSelectedProductOptions(
          handleOptionsOnRemove(option, selectedProductOptions),
        ),
      );
    } else {
      dispatch(
        setSelectedProductOptions(
          handleConflictOptions(option, selectedProductOptions, productOptions),
        ),
      );
    }
  };

  const onOptionChange = (option: ProductOption) => {
    if (!selectedProductOptions) return;
    dispatch(
      setSelectedProductOptions(
        updateProductOption(
          option,
          handleConflictOptions(option, selectedProductOptions, productOptions),
        ),
      ),
    );
  };

  return (
    <ProductConfiguratorContext.Provider
      value={{
        onOptionChange,
        addOrRemoveOption,
        productOptions,
        product,
        productSpecOptions,
        selectedProductOptions,
      }}
    >
      {children}
    </ProductConfiguratorContext.Provider>
  );
};

export const useProductConfiguratorContext =
  (): ProductConfiguratorProviderProps => {
    const context = useContext(ProductConfiguratorContext);
    if (!context) {
      throw new Error(
        "useProductConfiguratorContext must be used within a ProductConfiguratorContextProvider",
      );
    }
    return context;
  };
