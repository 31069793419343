import React, { useEffect } from "react";
import { UserManager } from "oidc-client";
import { useNavigate } from "react-router-dom";
import { selectProjectId, setProjectId } from "../../store/reducers/configReducer";
import Layout from "../Layout";
import { selectUser } from "../../store/reducers/userReducer";
import Title from "../ui/Title";
import Login from "../Login";
import { useAppSelector } from "../../hooks";
import { Project } from "../../types";
import { Icon } from "../ui/Icon";
import { Button } from "../ui/Button";
import ShareModal from "../ShareModal";
import {
  useFetchUserProjectsQuery,
  useUpdateProjectMutation,
} from "../../api/nest";
import PrismicTranslation from "../PrismicTranslation";

const FILTERS = {
  ALL: "all",
  CREATED: "created",
  RECEIVED: "received",
};

const reloadOnProjectChange = true;
const Projects = ({ userManager }: { userManager: UserManager }) => {
  const navigate = useNavigate();
  const projectId = useAppSelector(selectProjectId);
  const user = useAppSelector(selectUser);
  const [shareModalOpen, setShareModalOpen] = React.useState<boolean>(false);
  const [selectedProject, setSelectedProject] = React.useState<string>("");
  const [activeFilter, setActiveFilter] = React.useState<string>(FILTERS.ALL);
  const isLoggedIn = !!user;
  const { data: userProjects, isSuccess } = useFetchUserProjectsQuery(user?.id);
  const [projects, setProjects] = React.useState<Project[]>([]);
  const [updateProjectMutation] = useUpdateProjectMutation();

  useEffect(() => {
    if (isSuccess && userProjects.length) {
      setProjects(
        userProjects?.filter(
          (project: Project) => project.status !== "archived",
        ),
      );
    }
  }, [isSuccess, userProjects]);

  const remove = async (projectId: string) => {
    await updateProjectMutation({
      project: {
        id: projectId,
        status: "archived",
      }
    });
    setProjects(projects?.filter((project) => project.id !== projectId));
  };

  const edit = (projectId: string) => {
    if (reloadOnProjectChange) {
      window.location.replace(`/${projectId}`);
    } else {
      setProjectId(projectId);
      navigate(`/${projectId}`);
    }
  };

  const share = (projectId: string) => {
    setSelectedProject(projectId);
    setShareModalOpen(true);
  };

  const getProductConfigs = (project: Project) => {
    return project.productConfigs?.filter((config) => config.inCart);
  };

  const filteredProjects = projects?.filter((project) => {
    switch (activeFilter) {
      case FILTERS.CREATED:
        return project.projectOrder?.userId === user?.id;
      case FILTERS.RECEIVED:
        return project.projectOrder?.userId !== user?.id;
      default:
        return true;
    }
  });

  return (
    <Layout userManager={userManager} background>
      {isLoggedIn ? (
        <div className="flex flex-wrap flex-1 h-full">
          <div className="bg-gray flex flex-col w-full pt-4 lg:px-10">
            <div
              onClick={() => navigate(`/${projectId || ""}`)}
              className="cursor-pointer w-full flex items-center gap-2 ml-2 underline text-text text-sm"
            >
              <Icon icon="back" size="small" />
              <span>
                <PrismicTranslation field={"continue_shopping"} simple />
              </span>
            </div>
            <Title size={1} label="my_projects" />
            <div className="flex gap-2 mb-2 items-center">
              <Button
                onClick={() => setActiveFilter(FILTERS.ALL)}
                secondary={activeFilter !== FILTERS.ALL}
                label={FILTERS.ALL}
              />
              <Button
                onClick={() => setActiveFilter(FILTERS.CREATED)}
                secondary={activeFilter !== FILTERS.CREATED}
                label={FILTERS.CREATED}
              />
              <Button
                onClick={() => setActiveFilter(FILTERS.RECEIVED)}
                secondary={activeFilter !== FILTERS.RECEIVED}
                label={FILTERS.RECEIVED}
              />
            </div>
            {filteredProjects?.map((project) => (
              <div className="w-full bg-white p-4 border border-gray">
                <Title label={project.name} secondary />
                <div className="flex gap-4">
                  {getProductConfigs(project).map((config) => (
                    <img
                      className="w-[56px] h-[56px] object-contain"
                      src={config.productModelPicture || "/placeholder.png"}
                      alt={project.name}
                    />
                  ))}
                </div>
                <div className="text-text mt-1 flex gap-4">
                  <Button
                    onClick={() => remove(project.id)}
                    link
                    icon="delete"
                    label="remove"
                  />
                  <Button
                    onClick={() => share(project.id)}
                    link
                    icon="share"
                    label="share"
                  />
                  <Button
                    onClick={() => edit(project.id)}
                    link
                    icon="edit"
                    label="edit"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Login userManager={userManager} />
      )}
      <ShareModal
        isOpen={shareModalOpen}
        setIsOpen={setShareModalOpen}
        project={selectedProject}
      />
    </Layout>
  );
};

export default Projects;
