import Title from "../ui/Title";
import { FC } from "react";
import {
  CartProduct,
  Fabric,
  ProductOption,
  Size,
  SizingObject,
  Supplier,
} from "../../types";
import {
  getCartItemPrice,
  getCartItemSizesPrice,
  getMininumOrderQuantity,
  getPrismicTranslation,
  totalCartProductAmount,
} from "../../utils";
import { removeFromCart, updateAmount } from "../../store/reducers/cartReducer";
import { Input } from "../ui/Input";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectProjectId } from "../../store/reducers/configReducer";
import { useRemoveProductConfigMutation } from "../../api/nest";
import PrismicTranslation from "../PrismicTranslation";
import { selectLanguage } from "../../store/reducers/uiReducer";
import { useGetDocumentByTypeQuery } from "../../api/prismic";
import { EditDesign } from "./EditDesign";
import { DuplicateDesign } from "./DuplicateDesign";
import { Button } from "../ui/Button";

interface CartItemProps {
  cartItem: CartProduct;
  fabricSupplier: Supplier | null;
}

const CartItem: FC<CartItemProps> = ({ cartItem, fabricSupplier }) => {
  const dispatch = useAppDispatch();
  const language = useAppSelector(selectLanguage);
  const projectId = useAppSelector(selectProjectId);
  const orderQuantity = totalCartProductAmount(cartItem);
  const { data: settings } = useGetDocumentByTypeQuery({
    type: "settings",
    lang: "en-us",
  });

  const [removeProductConfig] = useRemoveProductConfigMutation();

  const remove = async () => {
    await removeProductConfig({
      cartItem: cartItem.id,
      project: projectId || "",
    });
    dispatch(removeFromCart(cartItem));
  };

  const handleCartAmount = (gender: string, size: any, amount: number) => {
    dispatch(updateAmount({ gender, id: cartItem.id, size, amount }));
  };

  return (
    <div className="w-full grid grid-cols-6 bg-white p-4 border border-gray gap-x-3 relative">
      <div className="flex col-span-6  md:col-span-2">
        <div className="flex pr-2">
          <img
            className="w-48 h-48 object-contain"
            src={cartItem.productModelPicture || "/placeholder.png"}
            alt={getPrismicTranslation(
              language,
              "name",
              cartItem?.product?.data,
            )}
          />
        </div>
        <div className="flex flex-col flex-wrap gap-2">
          <Title
            label={getPrismicTranslation(
              language,
              "name",
              cartItem?.productGroup?.data,
            )}
          />
          <span className="font-bold">
            {getCartItemPrice(cartItem, fabricSupplier).toFixed(2) + "€"}
          </span>
          <p className="text-text gap-2 mb-2">
            <PrismicTranslation field={"unit_price_features"} simple />
          </p>
          <div>
            <strong>
              <PrismicTranslation field={"product_fit"} simple />:
            </strong>
            <span className="text-text">
              {getPrismicTranslation(language, "name", cartItem?.product?.data)}
            </span>
          </div>
          <div className="flex flex-wrap items-center gap-2">
            <strong>
              <PrismicTranslation field={"main_color"} simple />
            </strong>
            <div
              className="w-2 h-2 rounded-full border"
              style={{
                backgroundColor: (cartItem.fabric?.main as Fabric)?.data
                  .web_format,
              }}
            />
            <span className="text-text">
              {getPrismicTranslation(
                language,
                "name",
                cartItem?.fabric?.main?.data,
              )}
            </span>
          </div>
          <div className="flex flex-wrap items-center gap-2">
            <strong>
              <PrismicTranslation field={"contrast_color"} simple />
            </strong>
            <div
              className="w-2 h-2 rounded-full border"
              style={{
                backgroundColor: (cartItem.fabric?.contrast as Fabric)?.data
                  .web_format,
              }}
            />
            <span className="text-text">
              {getPrismicTranslation(
                language,
                "name",
                cartItem?.fabric?.contrast?.data,
              )}
            </span>
          </div>
          <div className="flex flex-wrap gap-2">
            <strong>
              <PrismicTranslation field={"product_options"} simple />:
            </strong>
            <ul>
              {cartItem.productOptions?.map((option: ProductOption) => (
                <li key={option.id} className="text-text">
                  {getPrismicTranslation(language, "name", option?.data)}
                </li>
              ))}
            </ul>
          </div>
          <div className="text-text mt-1 flex flex-col">
            <EditDesign cartItem={cartItem} />
            <DuplicateDesign cartItem={cartItem} />
            <Button
              onClick={remove}
              className="text-text mt2  cursor-pointer"
              icon="delete"
              label="delete"
              link
            />
          </div>
        </div>
      </div>
      <div className="col-span-6 md:col-span-4">
        <Title label="choose_size" />
        <div
          className={`flex flex-wrap gap-4 ${
            orderQuantity < getMininumOrderQuantity(settings)
              ? "border border-red rounded p-2"
              : ""
          }`}
        >
          {orderQuantity < getMininumOrderQuantity(settings) && (
            <span className="text-red">
              <PrismicTranslation
                field={"min_order_quantity"}
                options={{
                  min_order_quantity: getMininumOrderQuantity(settings),
                }}
                simple
              />
            </span>
          )}

          {cartItem.amount?.map((sizingObject: SizingObject) => (
            <div className="w-full" key={sizingObject.id}>
              <div className="font-bold">
                <PrismicTranslation
                  field={sizingObject.primary?.translation_key}
                  simple
                />
              </div>
              <div className="flex flex-wrap gap-2">
                {sizingObject?.items?.map(({ size, amount }: Size) => (
                  <div key={size} className="flex w-[60px] flex-wrap">
                    <Input
                      label={size}
                      alternateLabel
                      value={amount}
                      placeholder={`0`}
                      type="number"
                      className={`text-center !text-black !p-0.5`}
                      onChange={(e) =>
                        handleCartAmount(
                          sizingObject.primary?.translation_key,
                          size,
                          e.currentTarget.valueAsNumber,
                        )
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="font-bold flex gap-2 md:block absolute right-4 top-4">
        {getCartItemSizesPrice(cartItem, fabricSupplier).toFixed(2) + "€"}
      </div>
    </div>
  );
};

export default CartItem;
