import { FC } from "react";
import ProductGroupSelector from "./ProductGroupSelector";
import ProductSelector from "./ProductSelector";
import FabricSelector from "./FabricSelector/index";
import { FeatureSelector } from "./FeatureSelector";
import { ProjectLogos } from "./ProjectLogos";
import ConfiguratorFooter from "./ConfiguratorFooter";
import { ProductModelImageUpload } from "./ProductModelImageUpload";
import { ProductConfiguratorProvider } from "./ProductConfiguratorProvider";

export const Configurator: FC = () => {
  return (
    <>
      <div className="flex-1 basis-auto overflow-y-scroll">
        <ProductGroupSelector />
        <ProductSelector />
        <FabricSelector />
        <ProductConfiguratorProvider>
          <ProjectLogos />
          <FeatureSelector />
        </ProductConfiguratorProvider>
      </div>
      <ConfiguratorFooter />
      <ProductModelImageUpload />
    </>
  );
};
